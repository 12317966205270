@font-face {
    font-family: 'Cabinet Grotesk';
    src: url('CabinetGrotesk-Bold.woff2') format('woff2'),
        url('CabinetGrotesk-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cabinet Grotesk';
    src: url('CabinetGrotesk-Extralight.woff2') format('woff2'),
        url('CabinetGrotesk-Extralight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cabinet Grotesk';
    src: url('CabinetGrotesk-Extrabold.woff2') format('woff2'),
        url('CabinetGrotesk-Extrabold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cabinet Grotesk';
    src: url('CabinetGrotesk-Black.woff2') format('woff2'),
        url('CabinetGrotesk-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cabinet Grotesk';
    src: url('CabinetGrotesk-Thin.woff2') format('woff2'),
        url('CabinetGrotesk-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cabinet Grotesk';
    src: url('CabinetGrotesk-Medium.woff2') format('woff2'),
        url('CabinetGrotesk-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cabinet Grotesk';
    src: url('CabinetGrotesk-Regular.woff2') format('woff2'),
        url('CabinetGrotesk-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cabinet Grotesk';
    src: url('CabinetGrotesk-Light.woff2') format('woff2'),
        url('CabinetGrotesk-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

